import React, {createContext, ReactChild} from 'react';
const EventEmitter = require('event-emitter');

type OnEventType = (eventType: string, listener: (...args: any[]) => any) => {};

// TODO: se podria fer un enum dels tipus de events per a que quedés més mono

export const EventBusContext = createContext({
  emit: (eventType: string, ...params: any[]) => {},
  on: (eventType: string, listener: (...args: any[]) => any) => {},
  remove: (eventType: string, listener: (...args: any[]) => any): void => {},
});

export default ({children}: {children: JSX.Element[] | JSX.Element}) => {
  const eventbus = new EventEmitter();

  return (
    <EventBusContext.Provider
      value={{
        emit: eventbus.emit,
        on: eventbus.on,
        remove: eventbus.removeListener,
      }}>
      {children}
    </EventBusContext.Provider>
  );
};
