import SafeAreaTab from '@/components/SafeAreaTab';
import React from 'react';
import {
  View,
  StyleSheet,
  useWindowDimensions,
  ScrollView,
  Text,
} from 'react-native';
import useThemeColor from '@/hooks/useThemeColor';
import {Ionicons} from '@expo/vector-icons';
import StyledPrimaryButton from '@/components/Styled/StyledPrimaryButton';
import {SafeAreaView} from 'react-native-safe-area-context';
import {gql, useQuery} from '@apollo/client';
import {Query} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/native';
import {RootStackParamList} from '@/types/navigation';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';

const CHECK_CONNECTION_QUERY = gql`
  query checkConnection {
    checkConnection
  }
`;

function MaintenancePage() {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
      backgroundColor: useThemeColor('background'),
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      color: useThemeColor('text'),
      textAlign: 'center',
    },
    text: {
      color: useThemeColor('text'),
      textAlign: 'center',
    },
    iconContainer: {
      padding: 10,
      borderRadius: 96,
      backgroundColor: useThemeColor('headerColor'),
      marginBottom: 16,
    },
  });
  const iconColor = useThemeColor('background');

  const {loading, error, refetch} = useQuery<Query>(CHECK_CONNECTION_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  async function checkConnection() {
    const {data} = await refetch();
    if (data.checkConnection) {
      navigation.reset({
        index: 0,
        routes: [{name: 'AuthenticatedUser'}],
      });
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.iconContainer}>
        <Ionicons name="construct-sharp" size={48} color={iconColor} />
      </View>
      <Text style={styles.title}>
        La aplicación està en modo mantenimiento.
      </Text>
      <Text style={styles.text}>
        Esta aplicación se encuentra actualmente en mantenimiento programado. En
        breve estaremos de vuelta.
      </Text>
      <StyledPrimaryButton
        onPress={() => checkConnection()}
        text="Volver a intentarlo"
        style={{marginTop: 12}}
        loading={loading}
      />
    </SafeAreaView>
  );
}

export default MaintenancePage;
