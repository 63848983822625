import React from 'react';
import AuthenticatedUser from '@/layouts/AuthenticatedUser';
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {CardStyleInterpolators} from '@react-navigation/stack';
import Login from '@/pages/Login';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import PostDetailPage from '@/pages/PostDetailPage';
import ActDetailPage from '@/pages/ActDetailPage';
import PostCommentsPage from '@/pages/PostCommentsPage';
import {store} from '@/redux/store';
import {Worker as User} from '@/types/generated-types';
import EditProfile from '@/pages/EditProfile';
import EditPassword from '@/pages/EditPassword';
import AboutPage from '@/pages/AboutPage';
import NewPasswordPage from '@/pages/NewPasswordPage';
import LegalWarningPage from '@/pages/LegalWarningPage';
import PrivacityPage from '@/pages/PrivacityPage';
import Wall_eProvider from '@/errors/Wall_e';
import ToastComponent from '@/components/Toast';
import MaintenancePage from '@/pages/MaintenancePage';
import VersionPage from '@/pages/VersionPage';
import useThemeColor from '@/hooks/useThemeColor';

export default () => {
  const Stack = createNativeStackNavigator();
  const user: User | undefined = store.getState().userReducer.user;
  const initialPage = user?.token ? 'AuthenticatedUser' : 'Login';

  return (
    <>
      <NavigationContainer>
        <Wall_eProvider>
          <Stack.Navigator
            initialRouteName={initialPage}
            screenOptions={{
              headerShown: false,
            }}>
            <Stack.Screen
              name="PostDetailScreen"
              component={PostDetailPage}
              options={{
                headerShown: true,
                headerTitle: '',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
                animationEnabled: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
            />
            <Stack.Screen
              name="ActDetailScreen"
              component={ActDetailPage}
              options={{
                headerShown: true,
                headerTitle: '',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
                animationEnabled: false,
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
              }}
            />
            <Stack.Screen
              name="PostCommentsScreen"
              component={PostCommentsPage}
              options={{
                headerShown: true,
                headerTitle: 'Comentarios',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
              }}
            />
            <Stack.Screen
              name="AuthenticatedUser"
              component={AuthenticatedUser}
            />
            <Stack.Screen name="Login" component={Login} />
            <Stack.Screen name="EditProfile" component={EditProfile} />
            <Stack.Screen name="EditPassword" component={EditPassword} />
            <Stack.Screen name="ResetPassword" component={ResetPasswordPage} />
            <Stack.Screen
              name="About"
              component={AboutPage}
              options={{
                headerShown: true,
                headerTitle: 'Información',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
              }}
            />
            <Stack.Screen
              name="Privacity"
              component={PrivacityPage}
              options={{
                headerShown: true,
                headerTitle: 'Privacidad',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
              }}
            />
            <Stack.Screen
              name="LegalWarning"
              component={LegalWarningPage}
              options={{
                headerShown: true,
                headerTitle: 'Aviso Legal',
                headerBackButtonMenuEnabled: true,
                headerBackTitleVisible: true,
                headerBackTitle: 'Volver',
                headerTintColor: useThemeColor('text'),
                headerStyle: {
                  backgroundColor: useThemeColor('background'),
                },
              }}
            />
            <Stack.Screen name="NewPasswordPage" component={NewPasswordPage} />
            <Stack.Screen name="Maintenance" component={MaintenancePage} />
            <Stack.Screen name="Version" component={VersionPage} />
          </Stack.Navigator>
        </Wall_eProvider>
      </NavigationContainer>
      <ToastComponent />
    </>
  );
};
