import React, {useState} from 'react';
import {
  useColorScheme,
  TextInput,
  Text,
  View,
  Image,
  StyleSheet,
} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useMutation} from '@apollo/client';
import {saveResetPasswordEmail} from '../redux/actions';
import {useDispatch} from 'react-redux';
import {MutationSendCodeForResetPasswordArgs} from '@/types/generated-types';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {ActivityIndicator} from 'react-native-paper';
import useErrorHandler from '@/hooks/useErrorHandler';
import StyledSecondaryButton from '@/components/Styled/StyledSecondaryButton';
import useThemeColor from '@/hooks/useThemeColor';

const SEND_CODE_FOR_PASSWORD_MUTATION = gql`
  mutation SendCodeForResetPassword($email: String!) {
    sendCodeForResetPassword(email: $email)
  }
`;

export default ({navigation}: Props) => {
  const [resetPasswordMutation] = useMutation(SEND_CODE_FOR_PASSWORD_MUTATION);
  const colorScheme = useColorScheme();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const {handle: handleError} = useErrorHandler();

  const onResetPassword = async () => {
    try {
      setisLoading(true);
      const data = await resetPasswordMutation({
        variables: {email: email} as MutationSendCodeForResetPasswordArgs,
      });
      if (data.data.sendCodeForResetPassword) {
        dispatch(saveResetPasswordEmail(email));
        onChangeEmail('');
        navigation.navigate('NewPasswordPage');
      }
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: useThemeColor('mainRed'),
    },
    input: {
      height: 44,
      padding: 10,
      marginBottom: 10,
      backgroundColor: 'white',
      borderRadius: 5,
    },
    button: {
      backgroundColor: colorScheme === 'dark' ? '#252525' : 'white',
    },
    logo: {
      width: 200,
      height: 97,
      borderRadius: 5,
    },
    mt4: {
      marginTop: 16,
    },
    loginButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2e67a7',
      padding: 12,
      borderRadius: 5,
    },
    loginButtonText: {
      color: 'white',
    },
    forgetPasswordButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
      borderRadius: 5,
    },
    infoText: {
      color: 'white',
      marginVertical: 16,
    },
  });
  const [email, onChangeEmail] = React.useState('');

  return (
    <View style={styles.container}>
      <View style={{marginTop: 10, width: 260}}>
        <View style={{alignItems: 'center'}}>
          <Image
            style={styles.logo}
            source={require('../assets/header-nomen.png')}
          />
        </View>
        <View>
          <TextInput
            value={email}
            onChangeText={onChangeEmail}
            placeholder={'Correo electrónico'}
            style={[styles.input, styles.mt4]}
          />
          <TouchableOpacity
            onPress={onResetPassword.bind(this)}
            style={styles.loginButton}>
            {isLoading && (
              <ActivityIndicator
                style={{marginRight: 18}}
                size={16}
                color="white"
              />
            )}
            <Text style={styles.loginButtonText}>Recuperar</Text>
          </TouchableOpacity>
          <Text style={styles.infoText}>
            Te enviaremos un correo con las instrucciones para recuperar tu
            contraseña.
          </Text>
          <StyledSecondaryButton
            onPress={() => navigation.goBack()}
            text="Cancelar"
            style={{
              backgroundColor: useThemeColor('onSurface'),
            }}
          />
        </View>
      </View>
    </View>
  );
};
