import useThemeColor from '@/hooks/useThemeColor';
import {Post} from '@/types/generated-types';
import React from 'react';
import {
  Dimensions,
  Text,
  Image,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
} from 'react-native';
import PostQuestion from './PostQuestion';

type Props = {post: Post | undefined};

function PostQuestions({post}: Props) {
  const styles = StyleSheet.create({
    title: {
      fontWeight: 'bold',
      color: useThemeColor('text'),
      fontSize: 24,
    },
  });

  return (
    <View
      style={{marginVertical: 24, display: 'flex', flexDirection: 'column'}}>
      {post?.questions?.length > 0 && (
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <Text style={styles.title}>Preguntas</Text>
          <Text
            style={[styles.title, {marginLeft: 4, color: styles.title.color}]}>
            ({post?.questionCount})
          </Text>
        </View>
      )}

      {post?.questions?.length > 0 &&
        post?.questions.map(question => (
          <View key={question.id}>
            <PostQuestion question={question} />
          </View>
        ))}
    </View>
  );
}

export default PostQuestions;
