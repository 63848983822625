import {Comment} from '@/types/generated-types';
import React, {useCallback, useEffect, useState} from 'react';
import {Text, StyleSheet, View} from 'react-native';
import formatDistance from '@/helpers/formatDistance';
import Avatar from '@/components/Avatar';
import useThemeColor from '@/hooks/useThemeColor';
import useGetStyles from '@/hooks/useGetStyles';

type Props = {comment: Comment};

function PostComment({comment}: Props) {
  const [timeAgo, setTimeAgo] = useState(formatDistance(comment.createdAt));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeAgo(formatDistance(comment.createdAt));
    }, 3000);

    // clearing interval
    return () => clearInterval(timer);
  });

  return (
    <View style={{marginVertical: 8}}>
      <View style={styles.userContainer}>
        <Avatar user={comment.worker} />
        <Text
          style={{
            marginLeft: 8,
            fontWeight: 'bold',
            color: useThemeColor('text'),
          }}>
          {comment.worker.name}
        </Text>
      </View>
      <View>
        <Text style={{marginBottom: 8, color: useThemeColor('text')}}>
          {comment.content}
        </Text>
        <Text style={{fontWeight: 'bold', color: useThemeColor('text')}}>
          {timeAgo}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  userContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  mt4: {
    marginTop: 16,
  },
});

export default PostComment;
