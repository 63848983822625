import useThemeColor from '@/hooks/useThemeColor';
import React from 'react';
import {
  TextInput,
  StyleSheet,
  TextInputIOSProps,
  TextInputProps,
} from 'react-native';

type Props = {
  value?: string | undefined;
  onChange?: (text: string) => void;
  placeholder?: string;
  type: TextInputIOSProps['textContentType'];
  style?: TextInputProps['style'];
  secureTextEntry?: TextInputProps['secureTextEntry'];
};

function StyledInput({
  value,
  onChange,
  placeholder,
  type,
  style,
  secureTextEntry,
}: Props) {
  const background = useThemeColor('background');
  const textColor = useThemeColor('text');

  const styles = StyleSheet.create({
    input: {
      height: 44,
      padding: 10,
      backgroundColor: background,
      color: textColor,
      borderRadius: 5,
      borderColor: '#ccc',
      borderWidth: 2,
    },
  });

  return (
    <TextInput
      value={value}
      onChangeText={onChange}
      placeholder={placeholder}
      placeholderTextColor={textColor}
      textContentType={type}
      style={[styles.input, style]}
      secureTextEntry={secureTextEntry}
    />
  );
}

export default StyledInput;
