import {AuxProps} from '@/helper';
import React, {createContext} from 'react';
import {ColorSchemeName, useColorScheme} from 'react-native';
import {
  DarkTheme as PaperDark,
  DefaultTheme as PaperLight,
  ThemeProvider as PaperThemeProvider,
} from 'react-native-paper';
import {
  DefaultTheme as NavigationLight,
  DarkTheme as NavigationDark,
  Theme as NavigationTheme,
  NavigationContainer,
} from '@react-navigation/native';
import Colors, {ColorTheme} from '@/contexts/Colors';

export type ThemeContext = {
  theme: ColorSchemeName;
  isDark: () => boolean;
  getThemeColor: (
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
  ) => string;
};

export const ThemeContext = createContext<ThemeContext>({
  theme: null,
  isDark: () => false,
  getThemeColor: () => '',
});

type PaperTheme = typeof PaperDark;

type ColorsMixin = PaperTheme['colors'] &
  NavigationTheme['colors'] &
  ColorTheme;

function makePaperTheme(colors: ColorsMixin): PaperTheme {
  return {
    ...PaperDark,
    colors: {
      ...colors,
      surface: colors.card,
    },
  };
}

const colorsDark: ColorsMixin = {
  ...PaperDark.colors,
  ...NavigationDark.colors,
  ...Colors.dark,
  primary: Colors.dark.surface,
};

const colorsLight: ColorsMixin = {
  ...PaperLight.colors,
  ...NavigationLight.colors,
  ...Colors.light,
  primary: Colors.dark.surface,
};

const paperDark = makePaperTheme(colorsDark);
const paperLight = makePaperTheme(colorsLight);

export function ThemeProvider({children}: AuxProps) {
  //force always light theme for Desktop
  const theme = 'light'; // useColorScheme();

  function isDark(): boolean {
    return theme === 'dark';
  }

  const paperTheme = isDark() ? paperDark : paperLight;

  function getThemeColor(
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
  ) {
    return Colors[theme ? theme : 'light'][colorName];
  }

  return (
    <ThemeContext.Provider value={{theme, isDark, getThemeColor}}>
      <PaperThemeProvider theme={paperTheme}>{children}</PaperThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
