import React, {ReactChild} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {FontAwesome5} from '@expo/vector-icons';
import useThemeColor from '@/hooks/useThemeColor';

type Props = {
  onPress: Function;
  icon: ReactChild;
  text: string;
};

function SettingButton({icon, text, onPress}: Props) {
  const textColor = useThemeColor('text');
  const softHighlight = useThemeColor('softHighlight');
  const iconColor = useThemeColor('text');

  const iconSize = 40;

  const styles = StyleSheet.create({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 4,
      paddingHorizontal: 6,
    },
    iconContainer: {
      width: iconSize,
      height: iconSize,
      maxWidth: iconSize,
      maxHeight: iconSize,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: softHighlight, //"#EFEDF2",
      borderRadius: 8,
      marginRight: 12,
    },
    text: {
      flex: 1,
      fontWeight: 'bold',
      color: textColor,
    },
  });

  return (
    <TouchableOpacity style={styles.container} onPress={() => onPress()}>
      <View style={styles.iconContainer}>{icon}</View>
      <Text style={styles.text}>{text}</Text>
      <FontAwesome5
        name="chevron-right"
        size={16}
        color={iconColor}
        style={{marginRight: 16}}
      />
    </TouchableOpacity>
  );
}

export default SettingButton;
