import React, {useCallback, useState} from 'react';
import {Platform, useWindowDimensions, View} from 'react-native';
import YoutubePlayer from 'react-native-youtube-iframe';

export default function YoutubeVideo(props: {youtubeId: string}) {
  const {width: AppWidth} = useWindowDimensions();

  const [playing, setPlaying] = useState(false);

  const onStateChange = useCallback(state => {
    if (state === 'ended') {
      setPlaying(false);
    }
  }, []);

  return (
    <View
      style={{
        width: AppWidth - 32,
        margin: 5,
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
      <YoutubePlayer
        height={((AppWidth - 32) * 9) / 16}
        width={AppWidth - 32}
        webViewStyle={{opacity: 0.99}}
        webViewProps={{
          renderToHardwareTextureAndroid: true,
          androidLayerType:
            Platform.OS === 'android' && Platform.Version <= 22
              ? 'hardware'
              : 'none',
        }}
        play={playing}
        videoId={props.youtubeId}
        onChangeState={onStateChange}
      />
    </View>
  );
}
