import {Post} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import {FontAwesome5} from '@expo/vector-icons';
import useThemeColor from '@/hooks/useThemeColor';

type Props = {post: Post};

function PostCommentCount({post}: Props) {
  const navigation = useNavigation();

  function goToSinglePostComments() {
    navigation.navigate('PostCommentsScreen', {
      postId: post.id,
    });
  }

  const styles = StyleSheet.create({
    textGray: {
      color: useThemeColor('text'),
      fontWeight: 'bold',
    },
    flexRow: {
      flexDirection: 'row',
    },
  });

  return (
    <TouchableOpacity
      onPress={() => goToSinglePostComments()}
      style={[
        styles.flexRow,
        {justifyContent: 'center', alignItems: 'center'},
      ]}>
      <FontAwesome5
        name="comment"
        size={18}
        color={useThemeColor('text')}
        style={{marginRight: 4}}
      />
      <Text style={styles.textGray}>{post.commentCount}</Text>
    </TouchableOpacity>
  );
}

export default PostCommentCount;
