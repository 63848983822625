import {Comment, MutationCommentArgs, Post} from '@/types/generated-types';
import React, {useContext, useEffect, useState} from 'react';
import {
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
} from 'react-native';
import PostComment from './PostComment';
import {gql, useMutation} from '@apollo/client';
import {Mutation, CommentInput} from '@/types/generated-types';
import {PostsContext} from '@/contexts/PostsProvider';
import StyledPrimaryButton from './Styled/StyledPrimaryButton';
import StyledInput from './Styled/StyledInput';
import Colors from '@/contexts/Colors';
import useThemeColor from '@/hooks/useThemeColor';
import useErrorHandler from '@/hooks/useErrorHandler';
import useGetStyles from '@/hooks/useGetStyles';

const COMMENT_MUTATION = gql`
  mutation Comment($commentInput: CommentInput!) {
    comment(commentInput: $commentInput) {
      id
      content
      createdAt
      post {
        id
        commentCount
        comments {
          id
          content
          createdAt
          worker {
            id
            name
            profileImage
          }
        }
      }
    }
  }
`;

type Props = {post: Post};

function PostComments({post: postProp}: Props) {
  const [commentMutation, {loading}] = useMutation<Mutation>(COMMENT_MUTATION);
  const [disabled, setDisabled] = useState(true);
  const [commentContent, setCommentContent] = useState('');
  const [post, setPost] = useState(postProp);
  const {onComment} = useContext<PostsContext>(PostsContext);
  const {handle: handleError} = useErrorHandler();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      maxWidth: useGetStyles('maxBodyWidth'),
      width: '100%',
      marginHorizontal: 'auto',
    },
    title: {
      fontWeight: 'bold',
      color: useThemeColor('text'),
      fontSize: 24,
    },
    input: {
      height: 44,
      padding: 10,
      marginBottom: 10,
      backgroundColor: 'white',
      borderRadius: 5,
      borderColor: '#eee',
      borderWidth: 2,
    },
    postButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.light.primary,
      padding: 12,
      borderRadius: 5,
    },
    postButtonText: {
      color: 'white',
    },
    list: {
      flex: 1,
      flexDirection: 'column',
      marginTop: 20,
      paddingHorizontal: 16,
      maxWidth: useGetStyles('maxBodyWidth'),
      width: '100%',
      marginHorizontal: 'auto',
    },
    postSeparator: {
      borderBottomWidth: 1,
      borderBottomColor: '#e5e7eb',
    },
  });

  const publishComment = async () => {
    try {
      const data = await commentMutation({
        variables: {
          commentInput: {
            comment: commentContent,
            postId: post.id,
          } as CommentInput,
        } as MutationCommentArgs,
      });

      const comment = data.data?.comment as Comment;
      setPost(comment.post);

      const payload = {
        postId: comment.post.id,
        commentCount: comment.post.commentCount,
        comment,
      };

      onComment(payload);
      setCommentContent('');
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    setDisabled(commentContent.length == 0);
  }, [commentContent]);

  function headerComponent() {
    return (
      <>
        {post.comments?.length > 0 && (
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={styles.title}>Comentarios</Text>
            <Text
              style={[
                styles.title,
                {marginLeft: 4, color: useThemeColor('text')},
              ]}>
              ({post?.commentCount})
            </Text>
          </View>
        )}
      </>
    );
  }

  return (
    <>
      <FlatList
        scrollEnabled={true}
        style={styles.list}
        snapToAlignment={'center'}
        ListHeaderComponent={headerComponent}
        renderItem={({item: post}) => (
          <PostComment comment={post} key={post.id} />
        )}
        data={post.comments}
        keyExtractor={element => element.id.toString()}
        ItemSeparatorComponent={() => <View style={styles.postSeparator} />}
      />
      <View
        style={{
          flexShrink: 0,
          paddingVertical: 6,
          paddingHorizontal: 16,
          maxWidth: useGetStyles('maxBodyWidth'),
          width: '100%',
          marginHorizontal: 'auto',
        }}>
        <StyledInput
          type="none"
          placeholder="Escribe tu comentario aquí..."
          style={{marginBottom: 10}}
          value={commentContent}
          onChange={setCommentContent}
        />
        <StyledPrimaryButton
          disabled={disabled}
          onPress={() => !disabled && publishComment()}
          text="Publicar comentario"
          loading={loading}
        />
      </View>
    </>
  );
}

export default PostComments;
