import SafeAreaTab from '@/components/SafeAreaTab';
import React from 'react';
import {
  View,
  StyleSheet,
  useWindowDimensions,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import RenderHtml, {RenderHTMLSource} from 'react-native-render-html';
import {gql, useQuery} from '@apollo/client';
import {Query, QueryDynamicPageArgs} from '@/types/generated-types';
import StyledPrimaryButton from '@/components/Styled/StyledPrimaryButton';
import {DynamicPages} from '@/types/DynamicPages';
import useThemeColor from '@/hooks/useThemeColor';
import useGetStyles from '@/hooks/useGetStyles';

const DYNAMIC_PAGE = gql`
  query DynamicPage($type: String!) {
    dynamicPage(type: $type) {
      content
    }
  }
`;

export default () => {
  const {width} = useWindowDimensions();
  const {loading, error, data, refetch} = useQuery<Query>(DYNAMIC_PAGE, {
    variables: {type: DynamicPages.ABOUT} as QueryDynamicPageArgs,
  });

  const styles = StyleSheet.create({
    container: {
      paddingVertical: 5,
      paddingHorizontal: 15,
      color: 'black',
      backgroundColor: useThemeColor('background'),
      maxWidth: useGetStyles('maxBodyWidth'),
      marginHorizontal: 'auto',
      width: '100%',
    },
    containerScroll: {
      paddingVertical: 5,
      paddingHorizontal: 15,
      color: 'black',
      backgroundColor: useThemeColor('background'),
      marginHorizontal: 'auto',
      width: '100%',
    },
    input: {
      width: 200,
      height: 44,
      padding: 10,
      borderWidth: 1,
      borderColor: 'black',
      marginBottom: 10,
    },
  });

  if (loading) {
    return (
      <SafeAreaTab>
        <ActivityIndicator style={{marginTop: 48}} size={24} color="gray" />
      </SafeAreaTab>
    );
  }

  return (
    <>
      <ScrollView horizontal={false} style={styles.containerScroll}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <View style={styles.container}>
            {error ? (
              <StyledPrimaryButton
                onPress={() => refetch()}
                text={'Recargar'}
                loading={loading}
              />
            ) : (
              <RenderHTMLSource
                contentWidth={width}
                source={{html: data?.dynamicPage.content as string}}
              />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};
