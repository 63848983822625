const textDark = '#000';
const textLight = '#fff';
const backgroundLight = '#fff';
const backgroundDark = '#000';
const darkerGray = '#20232a';
const darkGray = '#282c34';
const softHighlightDark = 'rgba(186, 235, 243, 0.5)';
const softHighlightLight = 'rgba(186, 235, 243, 0.5)';

const invariants = {
  accent: '#e1f1f5',
  mainRed: '#b01630',
  greenGlobals: '#78be1e',
  primary: '#2e67a7',
  toastError: '#ff0303',
  toastSuccess: '#0baf34',
};

const Colors = {
  textDark,
  textLight,
  darkerGray,
  invariants,
  light: {
    headerBackground: 'white',
    headerColor: textDark,
    text: textDark,
    background: backgroundLight,
    backgroundToast: backgroundDark,
    textToast: textLight,
    softHighlight: softHighlightLight,
    pressableBackground: 'white',
    pressableTint: invariants.mainRed,
    anchorBackground: 'rgba(187, 239, 253, 0.3)',
    card: 'white',
    surface: '#f2feff',
    onSurface: '#fff',
    activeTabItem: '#fff',
    inactiveTabItem: '#000',
    ...invariants,
  },
  dark: {
    headerBackground: darkerGray,
    headerColor: textLight,
    text: textLight,
    background: backgroundDark,
    backgroundToast: backgroundLight,
    textToast: textDark,
    softHighlight: softHighlightDark,
    pressableBackground: darkGray,
    pressableTint: invariants.mainRed,
    anchorBackground: 'rgba(97, 218, 251, 0.12)',
    card: '#282c34',
    surface: '#282c34',
    onSurface: '#fff',
    activeTabItem: '#000',
    inactiveTabItem: '#fff',
    ...invariants,
  },
};

export type ColorTheme = typeof Colors['dark'];

export default Colors;
