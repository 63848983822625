const Fonts = {
  textSize: 14,
  h1: 55,
  h2: 45,
  h3: 35,
  h4: 25,
  htmlCode: 14,
  seeComments: 16,
};

export default Fonts;
