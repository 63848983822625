import useErrorHandler from '@/hooks/useErrorHandler';
import useThemeColor from '@/hooks/useThemeColor';
import {MutationLoginArgs} from '@/types/generated-types';
import {Props} from '@/types/navigation';
import {gql, useMutation} from '@apollo/client';
import React, {useState} from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  useColorScheme,
  View,
} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {ActivityIndicator} from 'react-native-paper';
import {useDispatch} from 'react-redux';
import {saveUser} from '../redux/actions';

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    loginHolder(email: $email, password: $password) {
      id
      name
      email
      profileImage
      token
      isShareholder
      isWorker
    }
  }
`;

export default ({navigation}: Props) => {
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const colorScheme = useColorScheme();
  const dispatch = useDispatch();
  const {handle: handleError} = useErrorHandler();
  const [isLoading, setisLoading] = useState(false);

  const onLogin = async () => {
    try {
      setisLoading(true);
      const data = await loginMutation({
        variables: {email: email, password: password} as MutationLoginArgs,
      });
      dispatch(saveUser(data.data.loginHolder));
      onChangeEmail('');
      onChangePasword('');
      navigation.reset({
        index: 0,
        routes: [{name: 'AuthenticatedUser'}],
      });
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: useThemeColor('mainRed'),
    },
    input: {
      height: 44,
      padding: 10,
      marginBottom: 10,
      backgroundColor: 'white',
      borderRadius: 5,
    },
    button: {
      backgroundColor: colorScheme === 'dark' ? '#252525' : 'white',
    },
    logo: {
      width: 200,
      height: 97,
      borderRadius: 5,
    },
    mt4: {
      marginTop: 16,
    },
    loginButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2e67a7',
      padding: 12,
      borderRadius: 5,
    },
    loginButtonText: {
      color: 'white',
    },
    forgetPasswordButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
      borderRadius: 5,
    },
    forgetPasswordButtonText: {
      color: 'white',
    },
  });
  const [password, onChangePasword] = React.useState('');
  const [email, onChangeEmail] = React.useState('');

  return (
    <View style={styles.container}>
      <View style={{marginTop: 10, width: 260}}>
        <View style={{alignItems: 'center'}}>
          <Image
            style={styles.logo}
            source={require('../assets/header-nomen.png')}
          />
        </View>
        <View>
          <TextInput
            value={email}
            onChangeText={onChangeEmail}
            placeholder={'Correo electrónico'}
            textContentType="emailAddress"
            style={[styles.input, styles.mt4]}
          />
          <TextInput
            value={password}
            onChangeText={onChangePasword}
            placeholder={'Contraseña'}
            secureTextEntry={true}
            textContentType="password"
            style={styles.input}
          />
          <TouchableOpacity
            onPress={onLogin.bind(this)}
            style={styles.loginButton}>
            {isLoading && (
              <ActivityIndicator
                style={{marginRight: 18}}
                size={16}
                color="white"
              />
            )}
            <Text style={styles.loginButtonText}>Iniciar sesión</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate('ResetPassword')}
            style={styles.forgetPasswordButton}>
            <Text style={styles.forgetPasswordButtonText}>
              Has olvidado la contraseña?
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
