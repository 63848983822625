import {ThemeContext} from '@/contexts/ThemeProvider';
import useThemeColor from '@/hooks/useThemeColor';
import {VERSION} from '@env';
import React, {useContext} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';

function Footer() {
  const textColor = useThemeColor('text');
  const greenColor = useThemeColor('greenGlobals');

  const styles = StyleSheet.create({
    text: {fontSize: 12, color: textColor},
  });

  return (
    <View style={{marginVertical: 12}}>
      <Text style={[styles.text, {textAlign: 'center'}]}>
        Versión: {VERSION}
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        <Text numberOfLines={1} style={styles.text}>
          &copy; {new Date().getFullYear()} · Powered by
        </Text>
        <TouchableOpacity>
          <Text numberOfLines={1} style={[styles.text, {color: greenColor}]}>
            &nbsp;Globals&nbsp;
          </Text>
        </TouchableOpacity>
        <Text numberOfLines={1} style={styles.text}>
          · Todos los derechos reservados
        </Text>
      </View>
    </View>
  );
}

export default Footer;
