import ActsList from '@/components/ActsList';
import SafeAreaTab from '@/components/SafeAreaTab';
import {BlurView} from 'expo-blur';
import React from 'react';

export default () => {
  return (
    <SafeAreaTab>
      <ActsList />
      <BlurView
        intensity={95}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
    </SafeAreaTab>
  );
};
