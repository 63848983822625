import {formatDistanceToNowStrict, parseISO} from 'date-fns';
import {es} from 'date-fns/locale';

export default function formatDistance(date) {
  return formatDistanceToNowStrict(
    parseISO(date) - parseISO(date).getTimezoneOffset() * 60000,
    {locale: es, addSuffix: true},
  );
}

// export default function formatDistance(date) {
//     let d = new Date(date);
//     return formatDistanceToNowStrict(d, { locale: es, addSuffix: true });
// }
