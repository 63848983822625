import React, {useState} from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleProp,
  useColorScheme,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import {Image as ImageType} from '@/types/generated-types';
import PostVideo from '../PostVideo';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {useNavigation} from '@react-navigation/native';
import YoutubeVideo from '@/components/FeedElements/YoutubeVideo';
import useGetStyles from '@/hooks/useGetStyles';
import GalleryImage from './GalleryImage';

export default ({
  images,
  postId,
  youtubeId,
}: {
  images: ImageType[];
  postId: string | undefined;
  youtubeId: string;
}) => {
  const colorScheme = useColorScheme();
  const {width: AppWidth} = useWindowDimensions();

  const dotsContainerStyle: StyleProp<ViewStyle> = {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  };

  const normalDotStyle: StyleProp<ViewStyle> = {
    width: 7,
    height: 7,
    borderRadius: 5,
    backgroundColor: colorScheme === 'dark' ? '#c2c2c2' : '#333333',
    margin: 3,
    opacity: 0.2,
  };

  const highlightedDotStyle: StyleProp<ViewStyle> = {
    ...normalDotStyle,
    opacity: 1,
  };

  const [offset, setOffset] = useState(0);

  const currentDotIndex = () => {
    return Math.round(
      images.length - (AppWidth * images.length - offset) / AppWidth,
    );
  };

  const navigation = useNavigation();

  function goToSinglePost(post: number | string) {
    navigation.navigate('PostDetailScreen', {
      postId: post,
    });
  }

  return (
    <View>
      <ScrollView
        onScroll={event => setOffset(event?.nativeEvent?.contentOffset?.x)}
        showsHorizontalScrollIndicator={false}
        pagingEnabled={true}
        scrollEventThrottle={AppWidth}
        horizontal={true}
        snapToAlignment="center"
        overScrollMode="never">
        {images.map(media => {
          return media.is_video ? (
            <PostVideo key={media.url} url={media.url} />
          ) : (
            <TouchableOpacity
              key={media.url}
              onPress={() => (postId ? goToSinglePost(postId) : null)}>
              <GalleryImage image={media} />
            </TouchableOpacity>
          );
        })}
        {youtubeId.length > 0 && <YoutubeVideo youtubeId={youtubeId} />}
      </ScrollView>

      <View style={dotsContainerStyle}>
        {images.map((_, index) => {
          return (
            <View
              key={index}
              style={
                index === currentDotIndex()
                  ? highlightedDotStyle
                  : normalDotStyle
              }
            />
          );
        })}
        {youtubeId?.length > 0 && (
          <View
            key={images.length}
            style={
              images.length === currentDotIndex()
                ? highlightedDotStyle
                : normalDotStyle
            }
          />
        )}
      </View>
    </View>
  );
};
