import {ThemeContext} from '@/contexts/ThemeProvider';
import FeedPage from '@/pages/FeedPage';
import ActsPage from '@/pages/ActsPage';
import ProfilePage from '@/pages/ProfilePage';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import React, {useContext} from 'react';
import {useColorScheme} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {CardStyleInterpolators} from '@react-navigation/stack';
import {createMaterialBottomTabNavigator} from '@react-navigation/material-bottom-tabs';

const Tab = createMaterialTopTabNavigator();
// const Tab = createMaterialBottomTabNavigator();
export default () => {
  const colorScheme = useColorScheme();
  const {getThemeColor} = useContext<ThemeContext>(ThemeContext);

  return (
    <Tab.Navigator
      initialRouteName="Feed"
      barStyle={{
        backgroundColor: getThemeColor('mainRed'),
      }}
      activeColor={getThemeColor('activeTabItem')}
      inactiveColor={getThemeColor('inactiveTabItem')}
      shifting={true}
      screenOptions={{
        swipeEnabled: false,
        tabBarActiveTintColor: getThemeColor('activeTabItem'),
        tabBarInactiveTintColor: getThemeColor('inactiveTabItem'),
        tabBarLabelStyle: {fontSize: 12},
        tabBarStyle: {backgroundColor: getThemeColor('mainRed')},
        tabBarIndicatorStyle: {backgroundColor: getThemeColor('activeTabItem')},
      }}>
      <Tab.Screen
        name="Feed"
        component={FeedPage}
        options={{
          tabBarLabel: 'Noticias',
          tabBarIcon: ({color}) => (
            <MaterialCommunityIcons name="home" color={color} size={26} />
          ),
          animationEnabled: false,
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        }}
      />
      <Tab.Screen
        name="Acts"
        component={ActsPage}
        options={{
          tabBarLabel: 'Actas',
          tabBarIcon: ({color}) => (
            <MaterialCommunityIcons
              name="file-document"
              color={color}
              size={26}
            />
          ),
          animationEnabled: false,
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        }}
      />

      <Tab.Screen
        name="Profile"
        component={ProfilePage}
        options={{
          tabBarLabel: 'Usuario',
          tabBarIcon: ({color}) => (
            <MaterialCommunityIcons name="account" color={color} size={26} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
