export const SAVE_USER = 'SAVE_USER';
export const LOGOUT = 'LOGOUT';
export const SAVE_RESET_PASSWORD_EMAIL = 'SAVE_RESET_PASSWORD_EMAIL';
export const CLEAN_RESET_PASSWORD_EMAIL = 'CLEAN_RESET_PASSWORD_EMAIL';
import {Dispatch} from 'redux';
import {Worker as User} from '@/types/generated-types';

export const saveUser = (user: User) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_USER,
      payload: user,
    });
  };
};

export const logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

export const saveResetPasswordEmail = (email: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_RESET_PASSWORD_EMAIL,
      payload: email,
    });
  };
};

export const cleanResetPasswordEmail = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CLEAN_RESET_PASSWORD_EMAIL,
    });
  };
};
