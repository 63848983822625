import {Video, AVPlaybackStatus} from 'expo-av';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ImageStyle,
  StyleProp,
  useWindowDimensions,
  View,
} from 'react-native';
import {Entypo} from '@expo/vector-icons';
import {VideoContext} from '@/contexts/VideoProvider';

type Props = {
  url: string;
  index: any;
};

function PostVideo({url, index}: Props) {
  const video = useRef(null);
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const {width} = useWindowDimensions();
  const {play, currentVisibleIndex} = useContext(VideoContext);
  console.log('currentVisibleIndex: ', currentVisibleIndex);

  useEffect(() => {
    if (index !== currentVisibleIndex) {
      pauseVideo();
    }
  }, [currentVisibleIndex]);

  function onVideoLoaded() {
    setLoading(false);
  }

  function setNewStatus(newStatus: any) {
    setStatus(() => newStatus);
  }

  async function playVideo() {
    if (status.didJustFinish) {
      await video?.current?.stopAsync();
    }
    play(video);
  }

  function pauseVideo() {
    video?.current?.pauseAsync();
  }

  return (
    <View style={{position: 'relative'}}>
      <Video
        ref={video}
        style={{
          width: width - 32,
          height: 300,
          margin: 5,
          borderRadius: 10,
        }}
        paused={index !== currentVisibleIndex}
        source={{
          uri: url,
        }}
        // shouldPlay
        resizeMode="cover"
        useNativeControls
        onPlaybackStatusUpdate={status => setNewStatus(status)}
        onReadyForDisplay={() => onVideoLoaded()}
      />

      {!status.isPlaying && !loading && (
        <View
          style={{
            position: 'absolute',
            width: width - 32,
            height: 300,
            minHeight: 300,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#00000047',
            margin: 5,
            borderRadius: 10,
          }}>
          <TouchableOpacity onPress={() => playVideo()}>
            <Entypo name="controller-play" size={36} color="white" />
          </TouchableOpacity>
        </View>
      )}

      {status.isPlaying && (
        <TouchableOpacity
          onPress={() => pauseVideo()}
          style={{
            position: 'absolute',
            width: width - 32,
            height: 300,
            minHeight: 300,
            margin: 5,
            borderRadius: 10,
          }}
        />
      )}

      {loading && !status.isPlaying && (
        <View
          style={{
            position: 'absolute',
            width: width - 32,
            height: 300,
            minHeight: 300,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#00000047',
            margin: 5,
            borderRadius: 10,
          }}>
          <ActivityIndicator size={32} color="white" />
        </View>
      )}
    </View>
  );
}

export default PostVideo;
