import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {userReducer, resetPasswordReducer} from './reducers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {persistStore, persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['user', 'resetPasswordEmail'],
};

const rootReducer = combineReducers({
  userReducer: persistReducer(persistConfig, userReducer),
  resetPasswordReducer: persistReducer(persistConfig, resetPasswordReducer),
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
