import SafeAreaTab from '@/components/SafeAreaTab';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  Linking,
  Platform,
} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useQuery} from '@apollo/client';
import {ActivityIndicator} from 'react-native-paper';
import {Act, Query} from '@/types/generated-types';
import useThemeColor from '@/hooks/useThemeColor';
import useErrorHandler from '@/hooks/useErrorHandler';
import ActPublishedAt from '@/components/ActPublishedAt';
import useGetStyles from '@/hooks/useGetStyles';

const ACT_BY_ID_QUERY = gql`
  query ActByIdQuery($id: ID!) {
    act(id: $id) {
      id
      title
      publishedAt
      documentUrl
    }
  }
`;

export default ({route}: Props) => {
  const {width} = useWindowDimensions();
  const {actId} = route.params;
  const {handle: handleError} = useErrorHandler();

  const {loading, error, data} = useQuery<Query>(ACT_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {id: actId},
  });

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 12,
      paddingBottom: 32,
      backgroundColor: useThemeColor('background'),
      maxWidth: useGetStyles('maxBodyWidth'),
      width: '100%',
      marginHorizontal: 'auto',
    },
    subTitle: {
      lineHeight: 20,
      color: useThemeColor('text'),
    },
    postTitle: {
      fontWeight: 'bold',
      color: useThemeColor('text'),
      fontSize: 24,
    },
    postFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginTop: 12,
    },
    postEngagement: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });

  function openDocument(documentUrl: string) {
    if(Platform.OS == 'web'){
      window.open(documentUrl, '_blank');
    } else {
      Linking.openURL(documentUrl);
    }
  }

  if (loading) {
    return <ActivityIndicator style={{marginTop: 48}} size={24} color="gray" />;
  }
  if (error) {
    console.log(error);
    return <Text>Error :(</Text>;
  }

  return (
    <View
      style={{
        flex: 1,
        width: '100%',
        backgroundColor: useThemeColor('background'),
      }}>
      <ScrollView horizontal={false} style={styles.container}>
        <View style={{marginBottom: 16}}>
          <View style={styles.postFooter}>
            <ActPublishedAt act={data?.act as Act} />
          </View>

          <Text style={styles.postTitle}>{data?.act.title}</Text>

          <Text
            style={{color: 'blue'}}
            onPress={() => openDocument(data?.act.documentUrl)}>
            Ver documento
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};
