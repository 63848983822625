import {AuxProps} from '@/helper';
import React, {ReactChild, ReactChildren} from 'react';
import {useColorScheme, View} from 'react-native';
import {SafeAreaView, useSafeAreaInsets} from 'react-native-safe-area-context';

export default ({children}: AuxProps) => {
  const insets = useSafeAreaInsets();
  const colorScheme = useColorScheme();

  return (
    //TODO: implementa a la resta
    <SafeAreaView
      style={{
        backgroundColor: colorScheme === 'dark' ? '#000' : '#fff',
        flex: 1,
        paddingTop: insets.top,
      }}>
      {children}
    </SafeAreaView>
  );
};
