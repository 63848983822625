import React, {useEffect, useState} from 'react';
import {
  useColorScheme,
  TextInput,
  Text,
  Image,
  View,
  StyleSheet,
  Platform,
} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useMutation, useQuery} from '@apollo/client';
import {saveUser} from '../redux/actions';
import {useDispatch} from 'react-redux';
import {
  UpdateUserInput,
  MutationUpdateProfileArgs,
  Worker as User,
  Query,
} from '@/types/generated-types';
import {store} from '@/redux/store';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import {ReactNativeFile} from 'apollo-upload-client';
import * as mime from 'react-native-mime-types';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {Button as ButtonWithIcon} from 'react-native-elements';
import {ActivityIndicator} from 'react-native-paper';
import Avatar from '@/components/Avatar';
import {TouchableOpacity} from 'react-native-gesture-handler';
import StyledInput from '@/components/Styled/StyledInput';
import {SafeAreaView} from 'react-native-safe-area-context';
import StyledPrimaryButton from '@/components/Styled/StyledPrimaryButton';
import StyledSecondaryButton from '@/components/Styled/StyledSecondaryButton';
import useErrorHandler from '@/hooks/useErrorHandler';
import useThemeColor from '@/hooks/useThemeColor';
import useGetStyles from '@/hooks/useGetStyles';

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($worker: UpdateUserInput!) {
    updateProfile(worker: $worker) {
      id
      name
      email
      profileImage
      token
    }
  }
`;

const MY_PROFILE_QUERY = gql`
  query MyProfile {
    myProfile {
      id
      name
      email
      token
      profileImage
    }
  }
`;

function generateRNFile(uri: string, name: string) {
  return uri
    ? new ReactNativeFile({
        uri,
        type: mime.lookup(uri) || 'image',
        name,
      })
    : null;
}

export default ({navigation}: Props) => {
  const [updateProfileMutation, {loading}] = useMutation(
    UPDATE_PROFILE_MUTATION,
  );
  const colorScheme = useColorScheme();
  const dispatch = useDispatch();
  const {handle: handleError} = useErrorHandler();

  const [image, setImage] = useState(null as any);

  const onUpdateProfile = async () => {
    try {
      const userId = user?.id ? user.id : '';
      let inputProfile: UpdateUserInput = {
        name: name,
        email: email,
      };
      if (image) {
        inputProfile.file = generateRNFile(image, `picture-${Date.now()}`);
      }
      const data = await updateProfileMutation({
        variables: {
          worker: inputProfile as UpdateUserInput,
        } as MutationUpdateProfileArgs,
      });
      dispatch(saveUser(data.data.updateProfile));
      onChangeEmail('');
      onChangeName('');
      navigation.navigate('AuthenticatedUser');
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (Constants?.platform?.ios) {
        const {status} = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  async function pickImage() {
    const result = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.8,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.cancelled) {
      setImage(result.uri);
    }
  }

  async function pickImageFromGallery() {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.8,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (!result.cancelled) {
      setImage(result.uri);
    }
  }

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: useThemeColor('background'),
      paddingHorizontal: 12,
      paddingVertical: 12,
    },
    input: {
      width: 200,
      height: 44,
      padding: 10,
      borderWidth: 1,
      backgroundColor: useThemeColor('background'),
      marginBottom: 10,
    },
    loading: {
      margin: 16,
    },
    button: {
      backgroundColor: colorScheme === 'dark' ? '#252525' : '#252525',
    },
    inputButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2e67a7',
      padding: 12,
      borderRadius: 5,
      marginBottom: 20,
    },
    buttonText: {
      color: 'white',
    },
  });

  const {error, data} = useQuery<Query>(MY_PROFILE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const user = data?.myProfile as User;

  function showCamera(): boolean {
    return Platform.OS === 'ios' ? false : true;
  }

  useEffect(() => {
    if (error) {
      handleError(error);
      console.log('error', error);
    }
  }, [error]);

  const [name, onChangeName] = React.useState(user?.name);
  const [email, onChangeEmail] = React.useState(user?.email);

  const AVATAR_SIZE = 124;

  return (
    <SafeAreaView style={styles.container}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          maxWidth: useGetStyles('maxBodyWidth'),
          marginHorizontal: 'auto',
          width: '100%',
        }}>
        <View style={{flex: 1, justifyContent: 'center'}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            {(image && (
              <Image
                source={{uri: image}}
                style={{
                  width: AVATAR_SIZE,
                  height: AVATAR_SIZE,
                  borderRadius: AVATAR_SIZE,
                }}
              />
            )) || <Avatar user={user} size={AVATAR_SIZE} />}
          </View>
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around',
              maxHeight: 50,
              marginTop: 10,
              marginBottom: 10,
              flexDirection: 'row',
            }}>
            {showCamera() && (
              <ButtonWithIcon
                icon={
                  <MaterialCommunityIcons
                    name="camera"
                    color={'#FFF'}
                    size={22}
                    style={{marginRight: 5, paddingTop: 2}}
                  />
                }
                title="Cámara"
                onPress={pickImage}
                buttonStyle={{backgroundColor: styles.button.backgroundColor}}
              />
            )}
            <ButtonWithIcon
              icon={
                <MaterialCommunityIcons
                  name="image"
                  color={'#FFF'}
                  size={22}
                  style={{marginRight: 5, paddingTop: 2}}
                />
              }
              title="Galería"
              onPress={pickImageFromGallery}
              buttonStyle={{backgroundColor: styles.button.backgroundColor}}
            />
          </View>
          <StyledInput
            value={name}
            onChange={onChangeName}
            placeholder={'Nombre'}
            type="name"
            style={{marginBottom: 12}}
          />
          <StyledInput
            value={email}
            onChange={onChangeEmail}
            placeholder={'Correo electrónico'}
            type="emailAddress"
            style={{marginBottom: 12}}
          />
        </View>
        <StyledPrimaryButton
          onPress={onUpdateProfile.bind(this)}
          text="Guardar"
          loading={loading}
          style={{marginBottom: 12}}
        />
        <StyledSecondaryButton
          onPress={() => navigation.navigate('AuthenticatedUser')}
          text="Cancelar"
          style={{marginBottom: 12}}
        />
      </View>
    </SafeAreaView>
  );
};
