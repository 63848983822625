import SafeAreaTab from '@/components/SafeAreaTab';
import React, {useCallback, useContext} from 'react';
import {Text, View, useColorScheme, StyleSheet, ScrollView} from 'react-native';
import {Props} from '@/types/navigation';
import {logout} from '../redux/actions';
import {useDispatch} from 'react-redux';
import Avatar from '@/components/Avatar';
import {Worker, Query} from '@/types/generated-types';
import SettingButton from '@/components/Settings/SettingButton';
import {FontAwesome5} from '@expo/vector-icons';
import Footer from '@/components/Footer';
import {gql, useQuery} from '@apollo/client';
import useThemeColor from '@/hooks/useThemeColor';
import {VideoContext} from '@/contexts/VideoProvider';
import {useFocusEffect} from '@react-navigation/native';
import useGetStyles from '@/hooks/useGetStyles';

const MY_PROFILE_QUERY = gql`
  query MyProfile {
    myProfile {
      id
      name
      email
      token
      profileImage
    }
  }
`;

export default ({navigation}: Props) => {
  const dispatch = useDispatch();
  const colorScheme = useColorScheme();
  const {data} = useQuery<Query>(MY_PROFILE_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const user = data?.myProfile as Worker;
  const txtColor = useThemeColor('text');

  function pauseVideoOnFOcus() {
    const {stop} = useContext(VideoContext);
    const stopVideo = useCallback(() => {
      async function s() {
        // You can await here
        await stop();
      }
      s();
    }, [stop]);
    useFocusEffect(stopVideo);
  }

  //TODO reutilitzar este hook
  pauseVideoOnFOcus();

  const onLogout = async () => {
    dispatch(logout());
    navigation.reset({
      index: 0,
      routes: [{name: 'Login'}],
    });
  };

  const styles = StyleSheet.create({
    container: {
      padding: 20,
      backgroundColor: useThemeColor('background'),
    },
    input: {
      width: 200,
      height: 44,
      padding: 10,
      borderWidth: 1,
      borderColor: 'black',
      marginBottom: 10,
    },
    button: {
      backgroundColor: colorScheme === 'dark' ? 'white' : '#252525',
    },
    separator: {
      borderBottomWidth: 1,
      borderBottomColor: '#e5e7eb',
      marginHorizontal: 12,
      marginVertical: 8,
    },
  });

  return (
    <SafeAreaTab>
      <View
        style={{
          backgroundColor: useThemeColor('background'),
          flex: 1,
          paddingHorizontal: 1,
        }}>
        <View
          style={{
            flex: 1,
            paddingHorizontal: 1,
            maxWidth: useGetStyles('maxBodyWidth'),
            marginHorizontal: 'auto',
            width: '100%',
          }}>
          <View
            style={[
              styles.container,
              {justifyContent: 'center', alignItems: 'center'},
            ]}>
            <Avatar user={user} size={96} />
            <Text
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontWeight: 'bold',
                marginTop: 4,
                marginBottom: 2,
                paddingHorizontal: 15,
                color: txtColor,
              }}>
              {user?.name}
            </Text>
            <Text
              style={{
                fontSize: 15,
                marginBottom: 5,
                paddingHorizontal: 15,
                color: txtColor,
              }}>
              {user?.email}
            </Text>
          </View>

          <ScrollView
            horizontal={false}
            style={{backgroundColor: useThemeColor('background')}}>
            <SettingButton
              onPress={() => navigation.navigate('EditProfile')}
              text="Editar perfil"
              icon={<FontAwesome5 name="pencil-alt" size={18} color="#000" />}
            />
            <SettingButton
              onPress={() => navigation.navigate('EditPassword')}
              text="Cambiar contraseña"
              icon={<FontAwesome5 name="lock" size={18} color="#000" />}
            />

            <View style={styles.separator} />

            <SettingButton
              onPress={() => navigation.navigate('About')}
              text="Información"
              icon={<FontAwesome5 name="info" size={18} color="#000" />}
            />
            <SettingButton
              onPress={() => navigation.navigate('Privacity')}
              text="Privacidad"
              icon={<FontAwesome5 name="info-circle" size={18} color="#000" />}
            />
            <SettingButton
              onPress={() => navigation.navigate('LegalWarning')}
              text="Aviso Legal"
              icon={<FontAwesome5 name="book-open" size={18} color="#000" />}
            />
            <SettingButton
              onPress={onLogout.bind(this)}
              text="Cerrar sesión"
              icon={<FontAwesome5 name="sign-out-alt" size={18} color="#000" />}
            />
          </ScrollView>
          <Footer />
        </View>
      </View>
    </SafeAreaTab>
  );
};
