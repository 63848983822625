import {Post} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {EvilIcons} from '@expo/vector-icons';
import {FontAwesome5} from '@expo/vector-icons';
import useThemeColor from '@/hooks/useThemeColor';

type Props = {post: Post};

function PostQuestionCount({post}: Props) {
  const navigation = useNavigation();

  function goToSinglePostQuestions() {
    navigation.navigate('PostDetailScreen', {
      postId: post.id,
    });
  }

  const styles = StyleSheet.create({
    textGray: {
      color: useThemeColor('text'),
      fontWeight: 'bold',
    },
    flexRow: {
      flexDirection: 'row',
    },
    ml4: {
      marginLeft: 16,
    },
  });

  return (
    <View
      style={[
        styles.flexRow,
        {justifyContent: 'center', alignItems: 'center'},
        styles.ml4,
      ]}>
      <FontAwesome5
        name="question-circle"
        size={18}
        color={useThemeColor('text')}
        style={{marginRight: 4}}
      />
      <Text style={styles.textGray}>{post.questionCount}</Text>
    </View>
  );
}

export default PostQuestionCount;
