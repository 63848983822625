import Colors from '@/contexts/Colors';
import {ThemeContext} from '@/contexts/ThemeProvider';
import {useContext} from 'react';

export default function useThemeColor(
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
) {
  const {getThemeColor} = useContext(ThemeContext);

  return getThemeColor(colorName);
}
