import {EventBusContext} from '@/EventBus';
import {AuxProps} from '@/helper';
import {Comment, Post} from '@/types/generated-types';
import React, {createContext, useState} from 'react';

export const PostsContext = createContext<{
  posts: Post[];
  setPosts: Function;
  onLike: Function;
  onComment: Function;
}>({
  posts: [] as Post[],
  setPosts: (posts: Post[]) => {},
  onLike: ({postId, likeCount, isLiked}: LikeEventPayload) => {},
  onComment: ({postId, commentCount, comment}: CommentEventPayload) => {},
});

type LikeEventPayload = {postId: string; likeCount: number; isLiked: boolean};
type CommentEventPayload = {
  postId: string;
  commentCount: number;
  comment: Comment;
};

function PostsProvider({children}: AuxProps) {
  const [posts, setPosts] = useState([] as Post[]);

  /*
  const eventBus = useContext<EventBusContext>(EventBusContext);
  useEffect(() => {
    eventBus.on("like", ({ postId, likeCount, isLiked }: LikeEventPayload) => {
      console.log(postId);
      console.log(likeCount);
      console.log(isLiked);

      // ho fem amb un map perque aixi generem un array nou. si ho fessim simplement setejant-ho
      // com si fos una propietat d'un array, no faria el refresh del component.

      // Encara que no ho paregui, NO tornarà a renderitzar tots els components,
      // només tornarà a renderitzar aquest component; i això ho fa gràcies a la
      // propietat KEY que es en este cas ho posa automaticament perque utilitzem el component flatlist
      // i que si fessim nosaltres un foreach a muerte hauriem de posar. més info: https://reactjs.org/docs/lists-and-keys.html#keys
      console.log("posts from listener");
      console.log(posts);

      setPosts(
        posts.map((post) => {
          if (post.id == postId) {
            return {
              ...post,
              likeCount,
              isLiked,
            };
          }

          return post;
        })
      );
    });
  }, []);
  */

  function onLike({postId, likeCount, isLiked}: LikeEventPayload) {
    setPosts(
      posts.map(post => {
        if (post.id == postId) {
          return {
            ...post,
            likeCount,
            isLiked,
          };
        }

        return post;
      }),
    );
  }

  function onComment({postId, commentCount, comment}: CommentEventPayload) {
    setPosts(
      posts.map(post => {
        if (post.id == postId) {
          return {
            ...post,
            commentCount,
            // comments: [comment, ...post.comments],
          };
        }

        return post;
      }),
    );
  }

  return (
    <PostsContext.Provider value={{posts, setPosts, onLike, onComment}}>
      {children}
    </PostsContext.Provider>
  );
}

export default PostsProvider;
