import Colors from '@/contexts/Colors';
import useThemeColor from '@/hooks/useThemeColor';
import React from 'react';
import {
  TextInput,
  StyleSheet,
  TextInputIOSProps,
  TextInputProps,
  TouchableOpacity,
  Text,
  useColorScheme,
} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';

type Props = {
  onPress?: () => void;
  text?: string;
  loading?: boolean;
  style?: TextInputProps['style'];
  disabled?: boolean;
};

function StyledPrimaryButton({onPress, text, style, loading, disabled}: Props) {
  // TODO: dark mode
  const colorScheme = useColorScheme();

  const styles = StyleSheet.create({
    button: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.light.primary,
      padding: 12,
      borderRadius: 5,
    },
    buttonText: {
      color: useThemeColor('background'),
      fontWeight: 'bold',
    },
    disabled: {
      backgroundColor: colorScheme == 'dark' ? '#999' : '#dfdfdf',
    },
  });

  const iconColor = useThemeColor('background');

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.button, disabled ? styles.disabled : undefined, style]}
      disabled={disabled}>
      {loading && (
        <ActivityIndicator
          style={{marginRight: 18}}
          size={16}
          color={iconColor}
        />
      )}
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
}

export default StyledPrimaryButton;
