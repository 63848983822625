import React, {useState} from 'react';
import {StyleSheet, SafeAreaView, View} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useMutation} from '@apollo/client';
import {
  UpdatePasswordInput,
  MutationUpdatePasswordArgs,
} from '@/types/generated-types';
import {useDispatch} from 'react-redux';
import {saveUser} from '../redux/actions';
import StyledInput from '@/components/Styled/StyledInput';
import useThemeColor from '@/hooks/useThemeColor';
import StyledPrimaryButton from '@/components/Styled/StyledPrimaryButton';
import StyledSecondaryButton from '@/components/Styled/StyledSecondaryButton';
import useErrorHandler from '@/hooks/useErrorHandler';
import useGetStyles from '@/hooks/useGetStyles';

const UPDATE_PROFILE_MUTATION = gql`
  mutation updatePassword($passwordInput: UpdatePasswordInput!) {
    updatePassword(passwordInput: $passwordInput) {
      id
      name
      email
      profileImage
      token
    }
  }
`;

export default ({navigation}: Props) => {
  const [updateProfileMutation, {loading}] = useMutation(
    UPDATE_PROFILE_MUTATION,
  );
  const dispatch = useDispatch();
  const {handle: handleError} = useErrorHandler();

  const onUpdateProfile = async () => {
    try {
      const data = await updateProfileMutation({
        variables: {
          passwordInput: {
            password: password,
            oldPassword: oldPassword,
            password_confirmation: passwordConf,
          } as UpdatePasswordInput,
        } as MutationUpdatePasswordArgs,
      });
      dispatch(saveUser(data.data.updatePassword));
      onChangePasword('');
      onChangeOldPasword('');
      onChangePaswordConf('');
      navigation.navigate('AuthenticatedUser');
    } catch (e) {
      handleError(e);
    }
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: useThemeColor('background'),
      paddingHorizontal: 12,
      paddingVertical: 12,
    },
  });

  const [password, onChangePasword] = useState('');
  const [oldPassword, onChangeOldPasword] = useState('');
  const [passwordConf, onChangePaswordConf] = useState('');

  return (
    <SafeAreaView style={styles.container}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          maxWidth: useGetStyles('maxBodyWidth'),
          marginHorizontal: 'auto',
          width: '100%',
        }}>
        <View style={{flex: 1, justifyContent: 'center'}}>
          <StyledInput
            type="password"
            value={oldPassword}
            onChange={onChangeOldPasword}
            placeholder={'Contraseña actual'}
            secureTextEntry={true}
            style={{marginBottom: 10}}
          />
          <StyledInput
            type="password"
            value={password}
            onChange={onChangePasword}
            placeholder={'Nueva Contraseña'}
            secureTextEntry={true}
            style={{marginBottom: 10}}
          />
          <StyledInput
            type="password"
            value={passwordConf}
            onChange={onChangePaswordConf}
            placeholder={'Repetir la nueva Contraseña'}
            secureTextEntry={true}
            style={{marginBottom: 10}}
          />
        </View>
        <StyledPrimaryButton
          onPress={onUpdateProfile.bind(this)}
          style={{marginBottom: 10}}
          text="Guardar"
          loading={loading}
        />
        <StyledSecondaryButton
          onPress={() => navigation.navigate('AuthenticatedUser')}
          style={{marginBottom: 10}}
          text="Cancelar"
        />
      </View>
    </SafeAreaView>
  );
};
