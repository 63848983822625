import {AuxProps} from '@/helper';
import React, {createContext, useState} from 'react';

export type VideoContext = {
  video: null;
  currentVisibleIndex: number;
  setIndex: Function;
  play: Function;
  stop: Function;
};

/**
 * one video control
 * off view control
 */

export const VideoContext = createContext<VideoContext>({
  video: null,
  currentVisibleIndex: -1,
  setIndex: () => {},
  play: ref => {},
  stop: () => {},
});

export function VideoProvider({children}: AuxProps) {
  const [video, setVideo] = useState(null);
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(-1);
  async function play(newVideo) {
    await stop();
    setVideo(newVideo);
    newVideo?.current?.playAsync();
  }
  async function stop() {
    if (video) {
      await video?.current?.stopAsync();
    }
  }

  return (
    <VideoContext.Provider
      value={{video, play, stop, setIndex: setCurrentVisibleIndex, currentVisibleIndex}}>
      {children}
    </VideoContext.Provider>
  );
}

export default VideoProvider;
