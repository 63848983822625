import {Post} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import Gallery from './FeedElements/Gallery';
import PostLike from './PostLike';
import PostCommentCount from './PostCommentCount';
import PostQuestionCount from './PostQuestionCount';
import SeePostComments from './SeePostComments';
import useThemeColor from '@/hooks/useThemeColor';
import PostPublishedAt from './PostPublishedAt';
import useGetStyles from '@/hooks/useGetStyles';

type Props = {
  post: Post;
  index: any;
};

export default function PostItem({post, index}: Props) {
  const navigation = useNavigation();
  const textColor = useThemeColor('text');

  const styles = StyleSheet.create({
    flexRow: {
      flexDirection: 'row',
    },
    postContainer: {
      flexDirection: 'column',
      paddingHorizontal: 12,
      paddingVertical: 12,
      maxWidth: useGetStyles('maxBodyWidth'),
      width: '100%',
      marginHorizontal: 'auto',
    },
    postFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginTop: 12,
    },
    postTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: useThemeColor('text'),
    },
    postSubtitleContainer: {
      marginTop: 4,
    },
    subTitle: {
      lineHeight: 20,
      color: useThemeColor('text'),
    },
    textGray: {
      color: useThemeColor('text'),
    },
    postEngagement: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    ml4: {
      marginLeft: 16,
    },
  });

  function goToSinglePost(postId: number | string) {
    navigation.navigate('PostDetailScreen', {
      postId: postId,
    });
  }

  return (
    <View style={styles.postContainer}>
      {(post.images?.length > 0 || post.youtubeId?.length > 0) && (
        <Gallery
          images={post.images}
          postId={post.id}
          youtubeId={post.youtubeId ? post.youtubeId : ''}
          index={index}
        />
      )}
      <TouchableOpacity onPress={() => goToSinglePost(post.id)}>
        <View>
          <Text numberOfLines={1} style={styles.postTitle}>
            {post.title}
          </Text>
        </View>
        <View style={styles.postSubtitleContainer}>
          <Text style={styles.subTitle}>{post.subTitle}</Text>
        </View>

        <View style={styles.postFooter}>
          <View style={styles.postEngagement}>
            <PostCommentCount post={post} />
            <PostLike post={post} />
            <PostQuestionCount post={post} />
          </View>
          <PostPublishedAt post={post} />
        </View>
        <View>
          <SeePostComments post={post} />
        </View>
      </TouchableOpacity>
    </View>
  );
}
