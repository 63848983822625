import useThemeColor from '@/hooks/useThemeColor';
import {Post} from '@/types/generated-types';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text} from 'react-native';
import formatDistance from '@/helpers/formatDistance';

type Props = {post: Post};

function PostPublishedAt({post}: Props) {
  const styles = StyleSheet.create({
    postPublishedDate: {
      marginHorizontal: 8,
      color: useThemeColor('text'),
    },
  });

  const [timeAgo, setTimeAgo] = useState(formatDistance(post.publishedAt));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeAgo(formatDistance(post.publishedAt));
    }, 3000);

    // clearing interval
    return () => clearInterval(timer);
  });

  return (
    <Text numberOfLines={1} style={styles.postPublishedDate}>
      {post.publishedAt && timeAgo}
    </Text>
  );
}

export default PostPublishedAt;
