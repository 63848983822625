import {PostsContext} from '@/contexts/PostsProvider';
import {ThemeContext} from '@/contexts/ThemeProvider';
import {VideoContext} from '@/contexts/VideoProvider';
import useErrorHandler from '@/hooks/useErrorHandler';
import useThemeColor from '@/hooks/useThemeColor';
import {Post, Query, QueryPostsArgs, Act} from '@/types/generated-types';
import {gql, useQuery} from '@apollo/client';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {FlatList, StyleSheet, Text, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import ActItem from './ActItem';
import {Ionicons} from '@expo/vector-icons';
import StyledPrimaryButton from './Styled/StyledPrimaryButton';
import useGetStyles from '@/hooks/useGetStyles';

const ACTS_QUERY = gql`
  query Acts($page: Int!) {
    acts(first: 10, page: $page) {
      paginatorInfo {
        count
        currentPage
        hasMorePages
      }
      data {
        id
        title
        publishedAt
        documentUrl
      }
    }
  }
`;

const ActsList = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [page, setPage] = useState(1);
  const [actsData, setActsData] = useState([] as Act[]);
  const [hasMorePages, setHasMorePages] = useState(true);
  const {handle: handleError} = useErrorHandler();
  const {loading, error, data, refetch} = useQuery<Query, QueryPostsArgs>(
    ACTS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {page} as QueryPostsArgs,
    },
  );

  function handleRefresh() {
    setPage(1);
    setShowEmptyState(false);
    setHasMorePages(false);
    setIsRefreshing(true);
    setActsData([]);
    getAllActs();
  }

  useEffect(() => {
    getAllActs();
  }, [page]);

  useEffect(() => {
    setShowEmptyState(true);
  }, []);

  async function getAllActs() {
    const newData = await refetch({page});
    if (page === 1) {
      setActsData(newData.data?.acts?.data);
    } else {
      setActsData([...actsData, ...(newData.data?.acts?.data || [])]);
    }

    setHasMorePages(newData.data?.acts?.paginatorInfo.hasMorePages || false);

    setIsRefreshing(false);

    if (actsData.length === 0) {
      setShowEmptyState(true);
    }
  }

  function handleEnd() {
    if (!loading && hasMorePages) {
      setPage(page + 1);
    }
  }

  useEffect(() => {
    if (error) {
      handleError(error);
      console.log('error', error);
    }
  }, [error]);

  const {isDark} = useContext(ThemeContext);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: useThemeColor('background'),
    },
    list: {
      flex: 1,
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: useThemeColor('background'),
    },
    itemContainer: {
      maxWidth: useGetStyles('maxBodyWidth'),
      marginHorizontal: 'auto',
      width: '100%',
    },
    postSeparator: {
      borderBottomWidth: 1,
      borderBottomColor: isDark() ? '#222' : '#e5e7eb',
    },
    iconContainer: {
      padding: 10,
      borderRadius: 96,
      backgroundColor: useThemeColor('headerColor'),
      marginBottom: 16,
    },
    text: {
      color: useThemeColor('text'),
      textAlign: 'center',
    },
    loading: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const iconColor = useThemeColor('background');

  const renderEmpty = () =>
    !loading && showEmptyState ? (
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <Ionicons name="newspaper" size={48} color={iconColor} />
        </View>
        <Text style={styles.text}>No hay publicaciones</Text>
        <StyledPrimaryButton
          onPress={() => handleRefresh()}
          text="Volver a cargar"
          style={{marginTop: 12}}
          loading={loading}
        />
      </View>
    ) : (
      <></>
    );

  // TODO: show good errors UI
  if (error) {
    return <Text>Error :(</Text>;
  }

  const renderItem = ({item: act, index}: {item: Post}) => (
    <View style={styles.itemContainer}>
      <ActItem index={index} act={act} />
    </View>
  );

  return (
    <FlatList
      style={styles.list}
      snapToAlignment="start"
      disableIntervalMomentum
      showsVerticalScrollIndicator={false}
      renderItem={renderItem}
      keyExtractor={item => item.id}
      data={actsData}
      ItemSeparatorComponent={() => <View style={styles.postSeparator} />}
      refreshing={isRefreshing}
      ListEmptyComponent={renderEmpty}
      onRefresh={handleRefresh}
      onEndReached={handleEnd}
      onEndReachedThreshold={0.5}
      ListFooterComponent={() =>
        hasMorePages ? (
          <ActivityIndicator
            style={(styles.loading, {padding: 20})}
            size={24}
            color="gray"
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default ActsList;
