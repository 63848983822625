import React, {useCallback, useContext} from 'react';
import {Text} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useQuery} from '@apollo/client';
import {ActivityIndicator} from 'react-native-paper';
import {Query} from '@/types/generated-types';
import PostComments from '@/components/PostComments';
import {useFocusEffect} from '@react-navigation/native';
import {VideoContext} from '@/contexts/VideoProvider';

const POST_BY_ID_QUERY = gql`
  query PostByIdQuery($id: ID!) {
    post(id: $id) {
      id
      commentCount
      comments {
        id
        content
        createdAt
        worker {
          id
          name
          profileImage
        }
      }
    }
  }
`;

export default ({route}: Props) => {
  const {postId} = route.params;

  function pauseVideoOnFOcus() {
    const {stop} = useContext(VideoContext);
    const stopVideo = useCallback(() => {
      async function s() {
        // You can await here
        await stop();
      }
      s();
    }, [stop]);
    useFocusEffect(stopVideo);
  }

  //TODO reutilitzar este hook
  pauseVideoOnFOcus();

  const {loading, error, data} = useQuery<Query>(POST_BY_ID_QUERY, {
    variables: {id: postId},
  });

  if (loading) {
    return <ActivityIndicator style={{marginTop: 48}} size={24} color="gray" />;
  }
  if (error) {
    return <Text>Error :(</Text>;
  }

  return (
    <>
      <PostComments post={data?.post} />
    </>
  );
};
