import React, {useContext} from 'react';
import {Text, StyleSheet, TouchableOpacity} from 'react-native';
import {gql, useMutation} from '@apollo/client';
import {ActivityIndicator} from 'react-native-paper';
import {Post} from '@/types/generated-types';
import {FontAwesome5} from '@expo/vector-icons';
import {PostsContext} from '@/contexts/PostsProvider';
import useThemeColor from '@/hooks/useThemeColor';

// Define mutation
const INCREMENT_COUNTER = gql`
  mutation ($postId: ID!, $value: Boolean!) {
    changeLike(postId: $postId, value: $value) {
      id
      title
      isLiked
      likeCount
    }
  }
`;

type Props = {
  post: Post;
};

export default function PostLike({post}: Props) {
  const [changeLikeMutation, {loading}] = useMutation(INCREMENT_COUNTER);
  const {posts, onLike} = useContext<PostsContext>(PostsContext);

  async function changeLike() {
    const result = await changeLikeMutation({
      variables: {postId: post.id, value: !post.isLiked},
    });

    // TODO event bus
    // likeCount, post.isLiked
    const payload = {
      postId: result.data.changeLike.id,
      isLiked: result.data.changeLike.isLiked,
      likeCount: result.data.changeLike.likeCount,
    };

    console.log('postlike posts:');
    console.log(posts);

    // TODO: eventBus
    // eventBus.emit("like", payload);
    onLike(payload);
  }

  const styles = StyleSheet.create({
    flexRow: {
      flexDirection: 'row',
    },
    textGray: {
      color: useThemeColor('text'),
      fontWeight: 'bold',
    },
    ml4: {
      marginLeft: 16,
    },
  });

  return (
    <TouchableOpacity
      onPress={() => changeLike()}
      style={[
        styles.flexRow,
        {justifyContent: 'center', alignItems: 'center'},
        styles.ml4,
      ]}>
      {(loading && (
        <ActivityIndicator
          style={{marginRight: 12}}
          size={16}
          color={useThemeColor('text')}
        />
      )) || (
        <>
          {post.isLiked ? (
            <FontAwesome5
              name="heart"
              size={18}
              color={useThemeColor('mainRed')}
              style={{marginRight: 4}}
            />
          ) : (
            <FontAwesome5
              name="heart"
              size={18}
              color={useThemeColor('text')}
              style={{marginRight: 4}}
            />
          )}

          <Text style={styles.textGray}>{post.likeCount}</Text>
        </>
      )}
    </TouchableOpacity>
  );
}
