import {Question} from '@/types/generated-types';
import React, {useState, useEffect} from 'react';
import {Text, View} from 'react-native';
import RadioButton from '@/components/Forms/RadioButton';
import {gql, useMutation} from '@apollo/client';
import {
  Mutation,
  MutationVoteArgs,
  QuestionResult,
  Option,
} from '@/types/generated-types';
import useThemeColor from '@/hooks/useThemeColor';
import StyledPrimaryButton from './Styled/StyledPrimaryButton';
import Colors from '@/contexts/Colors';
import useErrorHandler from '@/hooks/useErrorHandler';

const VOTE_MUTATION = gql`
  mutation Vote($pollId: ID!, $optionId: ID!) {
    vote(pollId: $pollId, optionId: $optionId) {
      id
      option {
        id
        question {
          id
          results {
            option {
              id
              content
            }
            votes
            percentage
          }
          totalVotes
          userAlreadyVoted
        }
      }
    }
  }
`;

type Props = {question: Question};

type RunetimeQuestionOption = Option & {
  selected: boolean;
};

function PostQuestion({question}: Props) {
  const [isSelected, setIsSelected] = useState<RunetimeQuestionOption[]>(
    question.options.map((option: any) => ({
      ...option,
      selected: null,
    })),
  );

  const onRadioBtnClick = (item: RunetimeQuestionOption) => {
    let updatedState = isSelected.map(isSelectedItem =>
      isSelectedItem.id === item.id
        ? {...isSelectedItem, selected: true}
        : {...isSelectedItem, selected: false},
    );
    setIsSelected(updatedState);
    setDisabled(false);
  };

  const [voteMutation, {loading}] = useMutation<Mutation>(VOTE_MUTATION);
  const [disabled, setDisabled] = useState(true);
  const {handle: handleError} = useErrorHandler();

  const onVote = async () => {
    const optionId = isSelected.find(item => item.selected)?.id;
    const pollId = question.id;

    try {
      const data = await voteMutation({
        variables: {pollId: pollId, optionId: optionId} as MutationVoteArgs,
      });

      const vote = data.data?.vote;
      question = vote?.option.question as Question;
    } catch (e) {
      handleError(e);
    }
  };

  const txtColor = useThemeColor('text');

  return (
    <View style={{marginVertical: 8}}>
      <View>
        <Text style={{marginBottom: 8, color: txtColor}}>
          {question?.question}
        </Text>
      </View>
      {question.userAlreadyVoted ? (
        <View>
          <Text style={{color: txtColor}}>
            Resultados votación: {question.totalVotes}{' '}
            {question.totalVotes > 1 ? 'votos' : 'voto'}
          </Text>
          {question.results.map((optionResult: any, index) => (
            <View key={index} style={{paddingTop: 10}}>
              <Text style={{color: txtColor}}>
                {optionResult.option.content}
                {' - '}
                {optionResult.votes}
                {optionResult.votes > 1 ? ' votos - ' : ' voto - '}
                {optionResult.percentage} %
              </Text>
              <View
                style={{
                  padding: 5,
                  marginTop: 5,
                  width: optionResult.percentage + '%',
                  backgroundColor: Colors.light.primary,
                  height: 20,
                }}
              />
            </View>
          ))}
        </View>
      ) : (
        <View>
          {isSelected?.map((option: any) => (
            <RadioButton
              key={option.id}
              onPress={() => onRadioBtnClick(option)}
              selected={option.selected}>
              <Text
                style={{
                  flex: 1,
                  marginLeft: 5,
                  color: txtColor,
                }}>
                {option?.content}
              </Text>
            </RadioButton>
          ))}
          <StyledPrimaryButton
            disabled={disabled}
            onPress={() => !disabled && onVote()}
            text="Responder"
            loading={loading}
          />
        </View>
      )}
    </View>
  );
}

export default PostQuestion;
