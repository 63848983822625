import React, {useMemo, useState} from 'react';
import {Image, useWindowDimensions} from 'react-native';
import {Image as ImageType} from '@/types/generated-types';
import useGetStyles from '@/hooks/useGetStyles';

export default ({image}: {image: ImageType}) => {
  const AppWidth = Number(useGetStyles('maxBodyWidth').replace('px', ''));

  const [desiredHeight, setDesiredHeight] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getDesiredHeight(uri: string, desiredWidth: number): number {
    Image.getSize(uri, (width, height) => {
      setDesiredHeight((desiredWidth / width) * height);
    });
    return desiredHeight;
  }
  const height = useMemo(
    () => getDesiredHeight(image.url, AppWidth - 32),
    [AppWidth, getDesiredHeight, image.url],
  );
  return (
    <Image
      style={{
        height: height,
        width: AppWidth - 32,
        margin: 5,
        borderRadius: 10,
        resizeMode: 'contain',
      }}
      source={{uri: image.url}}
    />
  );
};
