import {
  SAVE_USER,
  LOGOUT,
  SAVE_RESET_PASSWORD_EMAIL,
  CLEAN_RESET_PASSWORD_EMAIL,
} from './actions';
import { Worker as User } from '@/types/generated-types';

const initialState = {
  user: undefined as User | undefined,
  resetPasswordEmail: undefined as string | undefined,
};

export function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_USER:
      return { ...state, user: action.payload };
    case LOGOUT:
      return { ...state, user: undefined };
    default:
      return state;
  }
}

export function resetPasswordReducer(state = initialState, action: any) {
  switch (action.type) {
    case SAVE_RESET_PASSWORD_EMAIL:
      return { ...state, resetPasswordEmail: action.payload };
    case CLEAN_RESET_PASSWORD_EMAIL:
      return { ...state, resetPasswordEmail: undefined };
    default:
      return state;
  }
}
