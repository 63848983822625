import {Act} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/core';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import useThemeColor from '@/hooks/useThemeColor';
import ActPublishedAt from './ActPublishedAt';

type Props = {
  act: Act;
  index: any;
};

export default function ActItem({act, index}: Props) {
  const navigation = useNavigation();
  const textColor = useThemeColor('text');

  const styles = StyleSheet.create({
    flexRow: {
      flexDirection: 'row',
    },
    postContainer: {
      flexDirection: 'column',
      paddingHorizontal: 12,
      paddingVertical: 12,
    },
    postFooter: {
      flexDirection: 'row',
      marginTop: 12,
    },
    postTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      color: useThemeColor('text'),
    },
    postSubtitleContainer: {
      marginTop: 4,
    },
    subTitle: {
      lineHeight: 20,
      color: useThemeColor('text'),
    },
    textGray: {
      color: useThemeColor('text'),
    },
    postEngagement: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    ml4: {
      marginLeft: 16,
    },
  });

  function goToSingleAct(actId: number | string) {
    navigation.navigate('ActDetailScreen', {
      actId: actId,
    });
  }

  return (
    <View style={styles.postContainer}>
      <TouchableOpacity onPress={() => goToSingleAct(act.id)}>
        <View>
          <Text numberOfLines={1} style={styles.postTitle}>
            {act.title}
          </Text>
        </View>

        <View style={styles.postFooter}>
          <ActPublishedAt act={act} />
        </View>
        {/* <Text>{act.documentUrl}</Text> */}
      </TouchableOpacity>
    </View>
  );
}
