import React from 'react';
import {View, StyleSheet, Text} from 'react-native';
import useThemeColor from '@/hooks/useThemeColor';
import {Ionicons} from '@expo/vector-icons';
import {SafeAreaView} from 'react-native-safe-area-context';

function VersionPage() {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      padding: 20,
      backgroundColor: useThemeColor('background'),
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 18,
      fontWeight: 'bold',
      color: useThemeColor('text'),
      textAlign: 'center',
    },
    text: {
      color: useThemeColor('text'),
      textAlign: 'center',
    },
    iconContainer: {
      padding: 10,
      borderRadius: 96,
      backgroundColor: useThemeColor('headerColor'),
      marginBottom: 16,
    },
  });
  const iconColor = useThemeColor('background');

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.iconContainer}>
        <Ionicons
          name="arrow-down-circle-outline"
          size={48}
          color={iconColor}
        />
      </View>
      <Text style={styles.title}>Por favor actualiza la aplicación.</Text>
      <Text style={styles.text}>
        La versión que tienes instalada se ha quedado obsoleta, y es necesario
        actualizar la aplicación para seguir funcionando.
      </Text>
    </SafeAreaView>
  );
}

export default VersionPage;
