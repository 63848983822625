import {AuxProps} from '@/helper';
import useThemeColor from '@/hooks/useThemeColor';
import React from 'react';
import {StyleSheet, TouchableOpacity, useColorScheme, View} from 'react-native';

export default (props: {
  onPress: () => void;
  selected: boolean;
  children: AuxProps['children'];
}) => {
  const mainRed = useThemeColor('mainRed');
  const backgroundColor = useThemeColor('text');

  const getBorderColor = () => {
    if (props.selected) {
      return mainRed;
    }

    return backgroundColor;
  };

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{
        borderStyle: 'solid',
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: getBorderColor(),
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 5,
        padding: 10,
      }}>
      <View
        style={{
          height: 20,
          width: 20,
          backgroundColor: useThemeColor('background'),
          borderRadius: 10,
          borderWidth: 1,
          borderColor: getBorderColor(),
          alignItems: 'center',
          justifyContent: 'center',
          margin: 5,
        }}>
        {props.selected ? (
          <View
            style={{
              height: 14,
              width: 14,
              borderRadius: 7,
              backgroundColor: mainRed,
            }}
          />
        ) : null}
      </View>
      {props.children}
    </TouchableOpacity>
  );
};
