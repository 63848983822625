import HTMLEngine from '@/components/HTMLEngine';
import Main from '@/layouts/Main';
import {Worker as User} from '@/types/generated-types';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {API_URL, VERSION} from '@env';
import {createUploadLink} from 'apollo-upload-client';
import {StatusBar} from 'expo-status-bar';
import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import PostsProvider from './contexts/PostsProvider';
import {ThemeProvider} from './contexts/ThemeProvider';
import VideoProvider from './contexts/VideoProvider';
import EventBus from './EventBus';
import {persistor, store} from './redux/store';

export default () => {
  const uploadLink = createUploadLink({
    uri: API_URL,
  });

  const authLink = setContext((_, {headers}) => {
    const user: User | undefined = store.getState().userReducer.user;
    const token = user?.token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'App-Version': VERSION,
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, uploadLink as unknown as ApolloLink]),
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <EventBus>
          <ApolloProvider client={client}>
            <ThemeProvider>
              <HTMLEngine>
                <StatusBar style="auto" />
                <PostsProvider>
                  <VideoProvider>
                    <Main />
                  </VideoProvider>
                </PostsProvider>
              </HTMLEngine>
            </ThemeProvider>
          </ApolloProvider>
        </EventBus>
      </PersistGate>
    </Provider>
  );
};
