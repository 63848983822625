import React, {useMemo} from 'react';
import {
  CustomTagRendererRecord,
  HTMLContentModel,
  HTMLElementModel,
  isDomElement,
  ListElementConfig,
  MixedStyleDeclaration,
  MixedStyleRecord,
  RenderHTMLConfig,
  RenderHTMLConfigProvider,
  TRenderEngineConfig,
  TRenderEngineProvider,
} from 'react-native-render-html';
import {findOne} from 'domutils';
import {TextProps} from 'react-native';
import Colors from '@/contexts/Colors';
import useThemeColor from '../hooks/useThemeColor';
import useGetFontSize from '@/hooks/useGetFontSize';

const tagsStyles: MixedStyleRecord = {
  li: {
    marginBottom: 10,
  },
  img: {
    alignSelf: 'center',
  },
  h1: {
    marginVertical: 15,
    paddingBottom: 5,
    fontSize: useGetFontSize('h1'),
    lineHeight: useGetFontSize('h1') * 1.2,
  },
  h4: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: useGetFontSize('h4'),
    lineHeight: useGetFontSize('h4') * 1.2,
  },
  code: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    fontSize: useGetFontSize('htmlCode'),
  },
  blockquote: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#fff8d8',
    borderLeftWidth: 10,
    borderLeftColor: '#ffe564',
    color: Colors.textDark,
  },
};

const ignoredDomTags = ['button', 'footer', 'form'];

const listConfig: ListElementConfig = {
  markerBoxStyle: {
    paddingRight: 6,
  },
};

const renderersProps: RenderHTMLConfig['renderersProps'] = {
  ol: listConfig,
  ul: listConfig,
};

const defaultTextProps: TextProps = {selectable: true};

export default function HTMLEngine({children}: React.PropsWithChildren<{}>) {
  const textColor = useThemeColor('text');
  const anchorBackground = useThemeColor('anchorBackground');
  const baseStyle: MixedStyleDeclaration = useMemo(
    () => ({
      color: textColor,
      fontSize: 14,
      // lineHeight: 14
    }),
    [textColor],
  );
  const tagsStyles: MixedStyleRecord = useMemo(
    () => ({
      ...tagsStyles,
      a: {
        color: textColor,
        backgroundColor: anchorBackground,
        textDecorationColor: textColor,
      },
    }),
    [textColor, anchorBackground],
  );

  return (
    <TRenderEngineProvider
      ignoredDomTags={ignoredDomTags}
      tagsStyles={tagsStyles}
      baseStyle={baseStyle}>
      <RenderHTMLConfigProvider>{children}</RenderHTMLConfigProvider>
    </TRenderEngineProvider>
  );
}
