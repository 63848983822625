import {Worker} from '@/types/generated-types';
import React from 'react';
import {Image} from 'react-native';

type Props = {user: Worker | undefined; size?: number | undefined};

function Avatar({user, size}: Props) {
  return (
    <>
      {user?.profileImage ? (
        <Image
          source={{
            uri: user?.profileImage,
          }}
          style={{
            width: size ? size : 36,
            height: size ? size : 36,
            borderRadius: size ? size * 0.9 : 24,
          }}
        />
      ) : (
        <Image
          source={require('@/assets/empty-avatar.jpg')}
          style={{
            width: size ? size : 36,
            height: size ? size : 36,
            borderRadius: size ? size * 0.9 : 24,
          }}
        />
      )}
    </>
  );
}

export default Avatar;
