import React from 'react';
import Toast from 'react-native-toast-message';
import {Text, View} from 'react-native';
import useThemeColor from '@/hooks/useThemeColor';

const toastConfig = {
  success: (props: any) => (
    <View
      style={{
        height: 60,
        borderLeftColor: useThemeColor('toastSuccess'),
        borderLeftWidth: 5,
        width: '90%',
        backgroundColor: useThemeColor('backgroundToast'),
        paddingHorizontal: 16,
        paddingVertical: 8,
      }}>
      <Text style={{color: useThemeColor('textToast'), fontWeight: 'bold'}}>
        {props.text1}
      </Text>
      {props.text2 && (
        <Text style={{color: useThemeColor('textToast')}}>{props.text2}</Text>
      )}
    </View>
  ),
  error: (props: any) => (
    <View
      style={{
        height: 'auto',
        borderLeftColor: useThemeColor('toastError'),
        borderLeftWidth: 5,
        width: '90%',
        backgroundColor: useThemeColor('backgroundToast'),
        paddingHorizontal: 16,
        paddingVertical: 8,
      }}>
      <Text style={{color: useThemeColor('textToast'), fontWeight: 'bold'}}>
        {props.text1}
      </Text>
      {props.text2 && (
        <Text style={{color: useThemeColor('textToast')}}>{props.text2}</Text>
      )}
    </View>
  ),
};

export default function ToastComponent(props: any) {
  return (
    <>
      <Toast config={toastConfig} />
    </>
  );
}
