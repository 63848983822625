import useGetFontSize from '@/hooks/useGetFontSize';
import {Post} from '@/types/generated-types';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';

type Props = {post: Post};

function SeePostComments({post}: Props) {
  const navigation = useNavigation();

  function goToSinglePostComments() {
    navigation.navigate('PostCommentsScreen', {
      postId: post.id,
    });
  }

  return (
    post.commentCount > 0 && (
      <TouchableOpacity
        onPress={() => goToSinglePostComments()}
        style={{marginVertical: 4}}>
        <Text style={styles.textGray}>
          Ver los {post.commentCount > 1 && post.commentCount} comentarios
        </Text>
      </TouchableOpacity>
    )
  );
}

const styles = StyleSheet.create({
  textGray: {
    color: 'gray',
    fontWeight: '700',
    fontSize: useGetFontSize('seeComments'),
    lineHeight: useGetFontSize('seeComments') * 1.2,
    paddingTop: 10,
  },
});

export default SeePostComments;
