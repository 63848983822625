import useThemeColor from '@/hooks/useThemeColor';
import React from 'react';
import {
  TextInput,
  StyleSheet,
  TextInputIOSProps,
  TextInputProps,
  TouchableOpacity,
  Text,
  useColorScheme,
} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';

type Props = {
  onPress?: () => void;
  text?: string;
  loading?: boolean;
  style?: TextInputProps['style'];
};

function StyledSecondaryButton({onPress, text, style, loading}: Props) {
  // TODO: dark mode
  const background = useThemeColor('background');
  const primaryColor = useThemeColor('primary');

  const styles = StyleSheet.create({
    button: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: background,
      padding: 12,
      borderRadius: 5,
      borderWidth: 2,
      borderColor: primaryColor,
    },
    buttonText: {
      color: primaryColor,
      fontWeight: 'bold',
    },
    loading: {marginRight: 18},
  });

  return (
    <TouchableOpacity onPress={onPress} style={[styles.button, style]}>
      {loading && (
        <ActivityIndicator
          style={styles.loading}
          size={16}
          color={primaryColor}
        />
      )}
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  );
}

export default StyledSecondaryButton;
