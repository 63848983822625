import React, {useState} from 'react';
import {
  useColorScheme,
  TextInput,
  Text,
  View,
  Image,
  StyleSheet,
} from 'react-native';
import {Props} from '@/types/navigation';
import {gql, useMutation} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {
  ResetPasswordInput,
  MutationResetPasswordArgs,
} from '@/types/generated-types';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {ActivityIndicator} from 'react-native-paper';
import {cleanResetPasswordEmail} from '@/redux/actions';
import {store} from '@/redux/store';
import useErrorHandler from '@/hooks/useErrorHandler';
import StyledSecondaryButton from '@/components/Styled/StyledSecondaryButton';
import useThemeColor from '@/hooks/useThemeColor';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`;

export default ({navigation}: Props) => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD_MUTATION);
  const colorScheme = useColorScheme();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const {handle: handleError} = useErrorHandler();

  const onResetPassword = async () => {
    try {
      setisLoading(true);
      const data = await resetPasswordMutation({
        variables: {
          resetPasswordInput: {
            password: password,
            password_confirmation: passwordConfirmation,
            token: token,
            email: store.getState().resetPasswordReducer.resetPasswordEmail,
          } as ResetPasswordInput,
        } as MutationResetPasswordArgs,
      });
      if (data.data.resetPassword) {
        onChangeToken('');
        onChangePassword('');
        onChangePasswordConfirmation('');
        dispatch(cleanResetPasswordEmail());
        navigation.reset({
          index: 0,
          routes: [{name: 'Login'}],
        });
      }
    } catch (e) {
      handleError(e);
    } finally {
      setisLoading(false);
    }
  };

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: useThemeColor('mainRed'),
      width: '100%',
    },
    input: {
      height: 44,
      padding: 10,
      marginBottom: 10,
      backgroundColor: 'white',
      borderRadius: 5,
    },
    button: {
      backgroundColor: colorScheme === 'dark' ? '#252525' : 'white',
    },
    logo: {
      width: 200,
      height: 97,
      borderRadius: 5,
    },
    mt4: {
      marginTop: 16,
    },
    loginButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#2e67a7',
      padding: 12,
      borderRadius: 5,
    },
    loginButtonText: {
      color: 'white',
    },
    forgetPasswordButton: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 12,
      borderRadius: 5,
    },
    infoText: {
      color: 'white',
      marginTop: 16,
    },
  });
  const [token, onChangeToken] = React.useState('');
  const [password, onChangePassword] = React.useState('');
  const [passwordConfirmation, onChangePasswordConfirmation] =
    React.useState('');

  return (
    <View style={styles.container}>
      <View style={{marginTop: 10, width: 260}}>
        <View style={{alignItems: 'center'}}>
          <Image
            style={styles.logo}
            source={require('../assets/header-nomen.png')}
          />
        </View>
        <View>
          <TextInput
            value={token}
            onChangeText={onChangeToken}
            placeholder={'Código de recuperación'}
            style={[styles.input, styles.mt4]}
          />
          <TextInput
            value={password}
            onChangeText={onChangePassword}
            placeholder={'Nueva contraseña'}
            secureTextEntry={true}
            style={[styles.input, styles.mt4]}
          />
          <TextInput
            value={passwordConfirmation}
            onChangeText={onChangePasswordConfirmation}
            placeholder={'Confirmación contraseña'}
            secureTextEntry={true}
            style={[styles.input, styles.mt4]}
          />
          <TouchableOpacity
            onPress={onResetPassword.bind(this)}
            style={styles.loginButton}>
            {isLoading && (
              <ActivityIndicator
                style={{marginRight: 18}}
                size={16}
                color="white"
              />
            )}
            <Text style={styles.loginButtonText}>Recuperar</Text>
          </TouchableOpacity>
          <StyledSecondaryButton
            onPress={() => navigation.goBack()}
            text="Cancelar"
            style={{
              marginTop: 12,
              backgroundColor: useThemeColor('onSurface'),
            }}
          />
        </View>
      </View>
    </View>
  );
};
