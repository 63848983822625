import registerErrors from '@/errors/register';
import {logout} from '@/redux/actions';
import {RootStackParamList} from '@/types/navigation';
import {Wall_e} from '@markdomkan/wall_e';
import {useNavigation} from '@react-navigation/native';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import React, {createContext, useContext, useEffect} from 'react';
import Toast from 'react-native-toast-message';
import {useDispatch} from 'react-redux';
export type ServiceBundleType = {
  showErrorMessageInAlert(message: string): void;
  navigate(route: keyof RootStackParamList): Promise<void>;
};

export const Wall_eContext = createContext<{
  handle(error: any): Promise<void>;
}>({
  handle: async _ => {},
});

export default (props: {children?: React.ReactNode}) => {
  const navigation =
    useNavigation<
      NativeStackNavigationProp<RootStackParamList, 'Maintenance'>
    >();
  const dispatch = useDispatch();

  const showToast = (message: string) => {
    Toast.show({
      type: 'error',
      text1: message,
      position: 'bottom',
    });
  };

  const serviceBundle: ServiceBundleType = {
    showErrorMessageInAlert: (message: string) => {
      showToast(message);
    },
    navigate: async (route: keyof RootStackParamList) => {
      if (route == 'Login') {
        dispatch(logout());
      }
      navigation.reset({
        index: 0,
        routes: [{name: route}],
      });
    },
  };

  const wall_e = new Wall_e(serviceBundle);

  registerErrors(wall_e);

  return (
    <Wall_eContext.Provider
      value={{
        handle: async error => await wall_e.handle(error),
      }}>
      {props.children}
    </Wall_eContext.Provider>
  );
};
