import SafeAreaTab from '@/components/SafeAreaTab';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import {Props} from '@/types/navigation';
import {RenderHTMLSource} from 'react-native-render-html';
import {gql, useQuery} from '@apollo/client';
import {ActivityIndicator} from 'react-native-paper';
import {Post, Query} from '@/types/generated-types';
import Gallery from '@/components/FeedElements/Gallery';
import PostQuestions from '@/components/PostQuestions';
import PostLike from '@/components/PostLike';
import PostCommentCount from '@/components/PostCommentCount';
import PostQuestionCount from '@/components/PostQuestionCount';
import useThemeColor from '@/hooks/useThemeColor';
import PostPublishedAt from '@/components/PostPublishedAt';
import SeePostComments from '@/components/SeePostComments';
import {useFocusEffect} from '@react-navigation/native';
import {VideoContext} from '@/contexts/VideoProvider';
import useErrorHandler from '@/hooks/useErrorHandler';
import useGetStyles from '@/hooks/useGetStyles';

const POST_BY_ID_QUERY = gql`
  query PostByIdQuery($id: ID!) {
    post(id: $id) {
      id
      title
      subTitle
      body
      images {
        url
        is_video
      }
      youtubeId
      publishedAt
      access
      isLiked
      likeCount
      commentCount
      questionCount
      questions {
        id
        question
        options {
          id
          content
        }
        results {
          option {
            id
            content
          }
          votes
          percentage
        }
        userAlreadyVoted
        totalVotes
      }
      likes {
        id
      }
    }
  }
`;

export default ({route}: Props) => {
  const {width} = useWindowDimensions();
  const {postId} = route.params;
  const {handle: handleError} = useErrorHandler();

  function pauseVideoOnFOcus() {
    const {stop} = useContext(VideoContext);
    const stopVideo = useCallback(() => {
      async function s() {
        // You can await here
        await stop();
      }
      s();
    }, [stop]);

    useFocusEffect(stopVideo);
  }

  //TODO reutilitzar este hook
  pauseVideoOnFOcus();

  const {loading, error, data} = useQuery<Query>(POST_BY_ID_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {id: postId},
  });

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 12,
      paddingBottom: 32,
      backgroundColor: useThemeColor('background'),
    },
    viewContainer: {
      maxWidth: useGetStyles('maxBodyWidth'),
      marginHorizontal: 'auto',
    },
    subTitle: {
      lineHeight: 20,
      color: useThemeColor('text'),
    },
    postTitle: {
      fontWeight: 'bold',
      color: useThemeColor('text'),
      fontSize: 24,
    },
    postFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginTop: 12,
    },
    postEngagement: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  });

  if (loading) {
    return <ActivityIndicator style={{marginTop: 48}} size={24} color="gray" />;
  }
  if (error) {
    console.log(error);
    return <Text>Error :(</Text>;
  }

  return (
    <>
      <ScrollView horizontal={false} style={styles.container}>
        <View style={styles.viewContainer}>
          {data &&
            (data?.post.images?.length > 0 ||
              data?.post.youtubeId?.length > 0) && (
              <Gallery
                images={data?.post.images ? data.post.images : []}
                youtubeId={data?.post.youtubeId ? data?.post.youtubeId : ''}
                postId={undefined}
              />
            )}

          <View style={{marginBottom: 16}}>
            <View style={styles.postFooter}>
              <View style={styles.postEngagement}>
                <PostCommentCount post={data?.post as Post} />
                <PostLike post={data?.post as Post} />
                <PostQuestionCount post={data?.post as Post} />
              </View>
              <PostPublishedAt post={data?.post as Post} />
            </View>

            <View>
              <SeePostComments post={data?.post as Post} />
            </View>

            <Text style={styles.postTitle}>{data?.post.title}</Text>
            <Text style={styles.subTitle}>{data?.post.subTitle}</Text>
          </View>
          <RenderHTMLSource
            contentWidth={width}
            source={{html: data?.post.body as string}}
          />
          <PostQuestions post={data?.post} />
        </View>
      </ScrollView>
    </>
  );
};
